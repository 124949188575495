import ImageXComponent from '@wix/thunderbolt-elements/src/components/ImageX/viewer/ImageX';
import ImageXController from '@wix/thunderbolt-elements/src/components/ImageX/viewer/ImageX.controller';


const ImageX = {
  component: ImageXComponent,
  controller: ImageXController
};


export const components = {
  ['ImageX']: ImageX
};

